import React, { FunctionComponent, useContext, useEffect, useState, useMemo } from "react";
import { DropDownOptionItem, PlainDropDown } from "../../../components/UI/Form/DropDownSelect/PlainDropDown";
import MultiSelectDropDown from "../../../components/UI/Form/DropDownSelect/MultiSelectDropDown";
import { DocumentGroup } from "../../../utils/interface";
import { AddDocumentRequiredProps } from "../DocumentsContainer";
import classes from "./PowerReport.module.css";
import PlainDragDropField from "../../../components/UI/Form/DragDropField/PlainDragDropField";
import Button from "../../../components/Button/Button";
import ImageImports from "../../../utils/ImageImports";
import { useGetDocumentTypes, useGetFile_TBReport } from "../../Reports/ReportsHttpServices";
import { Store } from "../../../Store";
import { getSortObjectsByKeyFn } from "../../../utils/Sortutils";
import { ModuleIDs } from "../../../utils/moduleIDs";
import ErrorCard from "../../../components/ErrorCard/ErrorCard";
import DragDropFieldInfo from "../../../components/UI/Form/DragDropField/DragDropFieldInfo";

const { trash } = ImageImports;

const AddEditGeneralDocument: FunctionComponent<AddDocumentRequiredProps> = ({ documentModule, documentGroups, onSave, siteUnits, isEdit, setAddDocumentError }) => {
  const [reportName, setReportName] = useState<string>("");
  const [documentType, setDocumentType] = useState<{ id: number | false; name: string }>();
  const [documentTypeId, setDocumentTypeId] = useState<number | false>();
  const [documentGroup, setDocumentGroup] = useState<DocumentGroup>();
  const [documentTypeOptions, setDocumentTypeOptions] = useState<{ id: number; name: string }[]>([]);
  const [attachment, setAttachment] = useState<File>();
  const { selectedUserGroup, report, header, showError } = useContext(Store);
  const [multiSelect, setMultiSelect] = useState<{ id: number; name: string }[]>([]);
  const [userGroupId, setUserGroupId] = useState<number[]>([]);
  const [selectionText, setSelectionText] = useState<string>("");
  const [editedFlag, setEditedFlag] = useState<boolean>(false);

  const sortDocumentGroups = getSortObjectsByKeyFn<DocumentGroup>("name", "asc", (d) => d.toString().toLowerCase().trim());

  useGetDocumentTypes({
    onSuccess: (data) => {
      setDocumentTypeOptions(
        data.map((dt) => ({
          id: dt.id,
          name: dt.name,
        }))
      );
    },
    enabled: true,
  });

  useEffect(() => {
    setReportName(attachment?.name || "");
    setAddDocumentError(undefined);
  }, [attachment]);

  useEffect(() => {
    setMultiSelect([...header.data.user.userGroups.map((a) => ({ id: a.id, name: a.name }))]);
  }, [header]);

  useEffect(() => {
    if (report && isEdit) {
      setDocumentGroup(documentGroups.find((dg) => dg.id === report.itemTypeId));
      setDocumentType(documentTypeOptions.find((dt) => dt.id === report.documentTypeId));
      setDocumentTypeId(report.documentTypeId);
      setUserGroupId(report.userGroupIds ?? []);
     }
  }, [report, documentTypeOptions, documentGroups, isEdit]);

  const { isFetching: isFetchingFile, isLoading: isLoadingFile } = useGetFile_TBReport(report.globalId, {
    enabled: report.globalId > 0 && isEdit,
    onSuccess: (data: Blob) => {
      setAttachment(new File([data], report.displayFileName, { type: data.type }));
    },
    onError: (error: any) => {},
  });

  const handleDocumentGroupSelection = (selectedOption: DropDownOptionItem) => {
    setDocumentGroup(documentGroups.find((dg) => dg.id === selectedOption.id));
    setEditedFlag(true);
  };

  const handleDocumentTypeSelection = (selectedOption: DropDownOptionItem) => {
    setDocumentType(documentTypeOptions.find((dt) => dt.id === selectedOption.id));
    setDocumentTypeId(selectedOption.id);
    setEditedFlag(true);
  };

  const submitDisabled = useMemo(() => {
    if (isEdit) {
      return !editedFlag || !((documentModule?.moduleId === ModuleIDs.UserGroupDocuments ? userGroupId.length > 0 : true) && reportName.length > 0 && documentGroup && documentType && attachment);
    } else {
      return !((documentModule?.moduleId === ModuleIDs.UserGroupDocuments ? userGroupId.length > 0 : true) && reportName.length > 0 && documentGroup && documentType && attachment)
    }
  }, [isEdit, userGroupId, reportName, documentGroup, documentType, attachment, editedFlag, documentModule]);

  const saveReport = () => {
    if (attachment && documentGroup) {
      const formData = new FormData();
      if (documentModule?.moduleId === ModuleIDs.UserGroupDocuments) {
        if (userGroupId.length > 0)
          userGroupId.forEach((e: number, i: number) => {
            formData.append(`UserGroupIds[${i}]`, `${e}`);
          });
      } else {
        if (selectedUserGroup.id > 0) formData.append("UserGroupIds[0]", `${selectedUserGroup.id}`);
      }

      if (!isEdit || report.fileModel.originalFileName !== reportName) {
        formData.append("File", attachment);
      }
      formData.append("ItemTypeId", `${documentGroup.id}`);
      formData.append("DisplayName", reportName);
      formData.append("DocumentTypeId", `${documentTypeId}`);

      if (isEdit && report) {
        formData.append('GlobalItemId', `${report.globalId}`);
        onSave([report.globalId, formData]);
      } else {
        onSave(formData);
      }
    }
  };

  useEffect(() => {
    let select = header.data.user.userGroups
      .filter((o1) => {
        return userGroupId.some((o2) => {
          return o1.id === o2;
        });
      })
      .map((a) => a.name);
    if (select.length > 1) {
      setSelectionText("Multiple Users’ Group Audiences");
    } else {
      setSelectionText(select.toString() ?? undefined);
    }
  }, [userGroupId]);

  const handleMultiSelection = (values: number[], toggled: number) => {
    let tmpValues = [...values];
    setUserGroupId(tmpValues);
    setEditedFlag(true);
  };

  return (
    <div className={`${classes.flex_column} ${classes.add_document_form}`}>
      {showError.isError && <ErrorCard ErrorMessage={showError.title} ErrorType={"danger"} />}
      <div className={`${classes.flex_row} ${classes.add_document_field}`}>
        <span className={`${classes.field_label} !basis-[30%]`}>Document Name*</span>
        <input type="text" value={reportName} disabled onChange={(e) => setReportName(e.target.value)} />
      </div>
      <div className={`${classes.flex_row} ${classes.add_document_field}`}>
        <span className={`${classes.field_label} !basis-[30%]`}></span>
        <div className="Flex Flex-row -mt-7 self-start text-xs pr-4 text-[#808080]">Document name will auto-populate to match the attachment's filename.</div>
      </div>

      {documentModule?.moduleId === ModuleIDs.UserGroupDocuments && (
        <>
          <div className={`${classes.flex_row} ${classes.add_document_field}`}>
            <span className={`${classes.field_label} !basis-[30%]`}>Users’ Group Audience(s)*</span>

            <MultiSelectDropDown
              className={`${classes.select_container} ${!!userGroupId.length ? classes.selected : classes.placeholder}`}
              selectClass={`${classes.flex_row} ${classes.select}`}
              optionsClass={`${classes.flex_column} ${classes.select_options}`}
              onSelection={handleMultiSelection}
              value={userGroupId}
              disabledClass={classes.select_disabled}
              noSelectionText="Select Users’ Group Audience(s)"
              hasSelectionText={selectionText}
              options={multiSelect ?? []}
            />
          </div>
          <div className={`${classes.flex_row} ${classes.add_document_field}`}>
            <span className={`${classes.field_label} !basis-[30%]`}></span>
            <div className="Flex Flex-row -mt-7 self-start text-xs  pr-4 text-[#808080]">You may select multiple users’ group audiences from this dropdown.</div>
          </div>
        </>
      )}

      <div className={`${classes.flex_row} ${classes.add_document_field}`}>
        <span className={`${classes.field_label} !basis-[30%]`}>Document Group*</span>

        <PlainDropDown
          value={documentGroup?.id}
          className={`${classes.select_container} ${!!documentGroup?.id ? classes.selected : classes.placeholder}`}
          selectClass={`${classes.flex_row} ${classes.select}`}
          optionsClass={`${classes.flex_column} ${classes.select_options}`}
          options={[{ id: false, name: "Select document group" }, ...documentGroups.sort(sortDocumentGroups)]}
          onSelection={handleDocumentGroupSelection}
        />
      </div>

      <div className={`${classes.flex_row} ${classes.add_document_field}`}>
        <span className={`${classes.field_label} !basis-[30%]`}>Document Type*</span>
        <PlainDropDown
          value={documentType?.id}
          className={`${classes.select_container} ${!!documentGroup?.id ? classes.selected : classes.placeholder}`}
          selectClass={`${classes.flex_row} ${classes.select}`}
          optionsClass={`${classes.flex_column} ${classes.select_options}`}
          options={[{ id: false, name: "Select document type" }, ...documentTypeOptions]}
          onSelection={handleDocumentTypeSelection}
        />
      </div>

      <div className={`${classes.flex_row} ${classes.add_document_field}`}>
        <span className={`${classes.field_label} !basis-[30%]`}>Attachment*</span>
        {attachment && (
          <>
            <input type="text" disabled className={classes.attachment_name} value={reportName} />
            <img className={classes.delete_file} onClick={() => setAttachment(undefined)} src={trash} alt="Remove Attachment" />
          </>
        )}
      </div>
      <div>
        <PlainDragDropField onChange={(e) => {setAttachment(e); setEditedFlag(true);}} attachment={attachment} fileLoading={isFetchingFile || isLoadingFile} />
        <DragDropFieldInfo fileTypes=".PDF, .JPG, .PNG" />
      </div>
      <div className={classes.flex_row}>
        <Button className={`darkBlue`} disabled={submitDisabled} text={`${isEdit ? 'Save' : 'Add'} Document`} onClick={saveReport} />
      </div>
    </div>
  );
};

export default AddEditGeneralDocument;
