import React, { createContext } from "react";
import {
  AccountSettingsDTO,
  ContactAccordianInterface,
  ContactAvatarImageDTO,
  DocumentsDTO,
  FaqInterfaceDTO,
  HeaderDTO,
  IssueReportItemsDTO,
  IssueReportsDTO,
  ModalsStateManagementDTO,
  NotificationType,
  OMManualSectionGetAllDTO,
  PartsAndServicesDTO,
  PlantConfigDTO,
  PlantConfigImageDTO,
  PlantContactsDTO,
  PlantEquipmentAttachmentDTO,
  PlantEquipmentAttachmentsDTO,
  PlantEquipmentDTO,
  PlantEquipmentIdDTO,
  PlantEquipmentsDTO,
  PlantInformation,
  PlantSiteDetailsDTO,
  QuoteRequestDTO,
  QuoteRequestItemsDTO,
  QuoteRequestListDTO,
  Report,
  SelectedPlantDTO,
  ShowErrorInterface,
  TypeDATA,
  TypeDTO,
  UnitDTO,
  UnitGetAllDTO,
  UserGroup,
} from "./utils/interface";

export interface StateInterface {
  header: HeaderDTO;
  accountSettings: AccountSettingsDTO;
  setHeader?: (value: HeaderDTO) => void;
  notificationTypes: NotificationType[];
  setNotificationTypes?: (value: NotificationType[]) => void;

  showA2hs: boolean;
  setShowA2hs?: (value: boolean) => void;

  setAccountSettings?: (value: AccountSettingsDTO) => void;
  plants: Array<{ plantId: number; name: string; isDefault: boolean }>;
  selectedPlant: SelectedPlantDTO;
  setSelectedPlant?: (value: SelectedPlantDTO) => void;

  plantConfigImage: PlantConfigImageDTO;
  setPlantConfigImage?: (value: PlantConfigImageDTO) => void;

  faq: FaqInterfaceDTO;
  setFaq?: (value: FaqInterfaceDTO) => void;

  selectedPlantDetails: PlantSiteDetailsDTO;
  setSelectedPlantDetails?: (value: PlantSiteDetailsDTO) => void;

  report: Report;
  setReport?: (value: Report) => void;

  plantEquipment: PlantEquipmentDTO;
  setPlantEquipment?: (value: PlantEquipmentDTO) => void;

  plantEquipments: PlantEquipmentsDTO;
  setPlantEquipments?: (value: PlantEquipmentsDTO) => void;

  typePlantContacts: PlantContactsDTO;
  setTypePlantContacts?: (value: PlantContactsDTO) => void;

  typeMitsubiContacts: PlantContactsDTO;
  setTypeMitsubiContacts?: (value: PlantContactsDTO) => void;

  plantEquipmentId: PlantEquipmentIdDTO;
  setPlantEquipmentId?: (value: PlantEquipmentIdDTO) => void;

  plantEquipmentsAttachment: PlantEquipmentAttachmentDTO;
  setPlantEquipmentsAttachment?: (value: PlantEquipmentAttachmentDTO) => void;

  plantEquipmentsAttachments: PlantEquipmentAttachmentsDTO;
  setPlantEquipmentsAttachments?: (value: PlantEquipmentAttachmentsDTO) => void;

  issueReports: IssueReportsDTO;
  setIssueReports?: (value: IssueReportsDTO) => void;

  issueReportItems: IssueReportItemsDTO;
  setIssueReportItems?: (value: IssueReportItemsDTO) => void;

  QuoteRequest: QuoteRequestDTO;
  setQuoteRequest?: (value: QuoteRequestDTO) => void;

  QuoteRequestItems: QuoteRequestListDTO;
  setQuoteRequestItems?: (value: QuoteRequestListDTO) => void;

  partsAndServices: PartsAndServicesDTO;
  setPartsAndServices?: (value: PartsAndServicesDTO) => void;

  plantConfig: PlantConfigDTO;
  setPlantConfig?: (value: PlantConfigDTO) => void;

  unit: UnitDTO;
  setUnit?: (value: UnitDTO) => void;

  unitGetAll: UnitGetAllDTO;
  setUnitGetAll?: (value: UnitGetAllDTO) => void;

  ommanualSectionGetAll: OMManualSectionGetAllDTO;
  setOMManualSectionGetAll?: (value: OMManualSectionGetAllDTO) => void;

  showError: ShowErrorInterface;
  setShowError?: (value: ShowErrorInterface) => void;

  showModalError: ShowErrorInterface;
  setShowModalError?: (value: ShowErrorInterface) => void;

  equipmentAttachmentType: TypeDTO;
  setEquipmentAttachmentType?: (value: TypeDTO) => void;

  manufacturerType: TypeDTO;
  setManufacturerType?: (value: TypeDTO) => void;

  productType: TypeDTO;
  setProductType?: (value: TypeDTO) => void;

  contractBasisTypes: TypeDTO;
  setContractBasisTypes?: (value: TypeDTO) => void;

  fuelType: TypeDTO;
  setFuelType?: (value: TypeDTO) => void;

  equipmentModelNameTypes: TypeDTO;
  setEquipmentModelNameTypes?: (value: TypeDTO) => void;

  unitTypes: TypeDATA;
  setUnitTypes?: (value: TypeDATA) => void;

  equipmentTypes: TypeDATA;
  setEquipmentTypes?: (value: TypeDATA) => void;

  unitConfigurationTypes: TypeDTO;
  setUnitConfigurationTypes?: (value: TypeDTO) => void;

  documentsDTO: DocumentsDTO;
  setDocumentsDTO?: (value: DocumentsDTO) => void;

  plantInformation: PlantInformation;
  setNameplate?: (value: { id: number }) => void;

  contactAvatarImage: ContactAvatarImageDTO[];
  setContactAvatarImage?: (value: ContactAvatarImageDTO[]) => void;

  accordian_tab: number;
  setAccordian_tab?: (value: number) => void;

  toolTip: boolean;
  toggleTooltip?: (value: boolean) => void;

  toolTipContent: React.ReactElement;
  setToolTipContent?: (value: React.ReactElement) => void;

  sidebarToggle: boolean;
  toggleSidebar?: (value: boolean) => void;

  mobileNavToggle: boolean;
  toggleMobileNav?: (value: boolean) => void;

  isModal: ModalsStateManagementDTO;
  setIsModal?: (value: ModalsStateManagementDTO) => void;

  trackLocation: string;
  setActiveLocation?: (value: string) => void;

  globalUniqueIds: string[];
  setGlobalUniqueIds?: (value: string[]) => void;

  userGroups: UserGroup[];
  setUserGroups?: (value: UserGroup[]) => void;

  selectedUserGroup: UserGroup;
  setSelectedUserGroup?: (value: UserGroup) => void;
}

export const defaultState: StateInterface = {
  header: {
    success: false,
    message: "",
    permissions: 0,
    data: {
      user: {
        userId: 0,
        firstName: "",
        lastName: "",
        pictureUrl: "",
        portalAdmin: false,
        mpInternal: false,
        userGroups: [{ id: 0, name: "", isDefault: false }],
        plants: []
      },
      notifications: []
    },
  },
  notificationTypes: [],
  accountSettings: {
	success: true,
	message: "",
	permissions: 0,
	data: {
	  fName: "",
	  lName: "",
	  email: "",
	  defaultBaseOrgId: 0,
	  defaultBaseOrgName: "",
	  optedOut: true
	}
  },
  showA2hs: false,
  showError: {
    title: "",
    isError: false,
    ErrorType: null,
  },
  showModalError: {
    title: "",
    isError: false,
    ErrorType: null,
  },
  contactAvatarImage: [
    {
      success: false,
      preview: "",
      name: "",
      error: "",
    },
  ],
  faq: {
    faqText: ""
  },
  isModal: {
    isCopy: {
      isCopyLinkModal: false,
    },
    isView: {
      isNameplate: false,
    },
    isAdd: {
      isEquipment: false,
      isContactModal: false,
      isDiscussionPost: false,
      isNameplate: false,
      isNameplateSamePage: false,
      isUnit: false,
      isPartsListDocument: false,
      isAttachNameplateAsset: false,
      isQuoteRequest: false,
    },
    isDelete: {
      isEquipment: false,
      isDiscussionPost: false,
      isPlantConfig: false,
      isNameplate: false,
      isUnit: false,
      isQuoteRequest: false,
    },
    isEdit: {
      isEquipment: false,
      isDiscussionPost: false,
      isPlantConfig: false,
      isContactModal: false,
      isNameplate: false,
      isFaq: false,
      isUnit: false,
      isPartsListDocument: false,
      isAttachNameplateAsset: false,
      isQuoteRequest: false,
    },
    isDeleteSuccess: {
      isEquipment: false,
      isContactModal: false,
      isDiscussionPost: false,
      isPlantConfig: false,
      isNameplate: false,
      isUnit: false,
      isQuoteRequest: false,
    },
    isAddedSuccess: {
      isEquipment: false,
      isContactModal: false,
      isNameplate: false,
      isDiscussionPost: false,
      isNameplateSamePage: false,
      isUnit: false,
      isQuoteRequest: false,
    },
    isEditedSuccess: {
      isEquipment: false,
      isContactModal: false,
      isFaq: false,
      isPlantConfig: false,
      isDiscussionPost: false,
      isNameplate: false,
      isUnit: false,
      isQuoteRequest: false,
    },
  },
  report: {
    reportType: "",
    globalId: 0,
    itemTypeId: 0,
    created: "",
    createdBy: "",
    updated: "",
    updatedBy: "",
    displayFileName: "",
    associatedEquipment: [],
    fileId: 0,
    documentType: "",
    documentTypeId: 0,
    fileModel: {
      globalItemId: 0,
      id: 0,
      location: "",
      originalFileName: "",
      uploaded: "",
      displaySize: "",
      fileType: {
        contentType: "",
        description: "",
        extension: "",
        id: 0,
        maxSizeMb: 0,
        name: "",
      },
      fileTypeId: 0,
    },
    scope: "",
    issueDate: "",
    applicability: "",
    summaryGuideline: "",
    implementationStatus: "",
    isDeleted: false,
    previousFiles: [],
    sectionId: 0,
  },
  selectedPlant: { plantId: 0, baseOrgId: 0, name: "", isDefault: false },

  selectedPlantDetails: {
    success: false,
    message: "",
    permissions: 0,
    data: {
      plantId: 4,
      plantLoad: "",
      ambientTemperatureFahrenheit: "",
      ambientTemperatureCelsius: "",
      lastOutage: '',
      nextPlannedOutage: '',
    },
  },

  issueReports: {
    success: false,
    message: "",
    permissions: 0,
    data: {
      totalCount: 0,
      newIssueReportsCount: 0,
      agedIssueReportsCount: 0,
      resolvedIssueReportsCount: 0,
      averageOpenTime: 0,
    },
  },

  issueReportItems: {
    success: false,
    message: "",
    permissions: 0,
    data: [
      {
        orgGroup: "",
        baseOrg: {
          id: 0,
          name: "",
          description: "",
          spLegacyID: 0,
        },
        role: "",
        permissionName: "",
        canView: false,
        canEdit: false,
        canAdd: false,
        canDelete: false,
        canAdmin: false,
        items: [],
      },
    ],
  },

  QuoteRequest: {
    success: false,
    message: "",
    permissions: 0,
    data: {
      totalCount: 0,
      newQuoteRequestCount: 0,
      agedQuoteRequestCount: 0,
      resolvedQuoteRequestCount: 0,
      averageOpenTime: 0,
    },
  },

  QuoteRequestItems: {
    success: false,
    message: "",
    permissions: 0,
    data: [
      {
        quoteRequestId:          0,
  globalItemId:            0,
  baseOrgId:               0,
  itemTypeId:              0,
  description:             "",
  quotePriorityId:         0,
  plantSiteId:             0,
  quoteNeededDate:         "",
  partsPriorityId:         0,
  partsNeededDate:         "",
  partsRequestReasoningId: 0,
  isPlannedOutage:         false,
  outageSeasonId:          0,
  outageYear:              0,
  equipmentId:             0,
  customerContactId:       0,
  additionalComments:      "",
  internalOutageId:        "",
  quoteRequestParts:       [],
  quoteRequestEvents:      [],
  quoteRequestType:        0,
  quoteTypeId:             0,
      },
    ],
  },

  equipmentModelNameTypes: {
    success: false,
    message: "",
    permissions: 0,
    data: [
      {
        id: 0,
        name: "",
      },
    ],
  },
  fuelType: {
    success: false,
    message: "",
    permissions: 0,
    data: [
      {
        id: 0,
        name: "",
      },
    ],
  },
  manufacturerType: {
    success: false,
    message: "",
    permissions: 0,
    data: [
      {
        id: 0,
        name: "Other OEM",
      },
    ],
  },
  productType: {
    success: false,
    message: "",
    permissions: 0,
    data: [
      {
        id: 0,
        name: "",
      },
    ],
  },
  unitTypes: {
    data: [],
  },
  equipmentTypes: {
    data: [
      {
        id: 0,
        name: "",
      },
    ],
  },
  unitConfigurationTypes: {
    success: false,
    message: "",
    permissions: 0,
    data: [
      {
        id: 0,
        name: "",
      },
    ],
  },
  equipmentAttachmentType: {
    success: false,
    message: "",
    permissions: 0,
    data: [
      {
        id: 0,
        name: "",
      },
    ],
  },
  contractBasisTypes: {
    success: false,
    message: "",
    permissions: 0,
    data: [
      {
        id: 0,
        name: "",
      },
    ],
  },

  partsAndServices: {
    success: false,
    message: "",
    permissions: 0,
    data: {
      totalCount: 0,
      partsQuotesAndOrdersCount: 0,
      servicesQuotesAndOrdersCount: 0,
      averagePartsTurnaroundTime: 0,
      averageServicesTurnaroundTime: 0,
    },
  },

  documentsDTO: {
    success: false,
    message: "",
    permissions: 0,
    data: {
      totalCount: 0,
      newMitsubishiReportsCount: 0,
      newTechnicalBulletinsCount: 0,
      oandMManualsCount: 0,
    },
  },
  plantConfigImage: {
    file: "",
  },
  plantEquipment: {
    success: false,
    message: "",
    permissions: 0,
    data: {
      plantEquipmentId: 0,
      unitID: 0,
      equipmentName: "",
      equipmentID: "",
      productType: 0,
      manufacturerType: 0,
      contractBasis: 0,
      equipmentModel: "",
      equipmentModelId: 0,
      productTypeName: "",
      contractBasisName: "",
      equipmentModelName: "",
      manufacturerName: "",
      any: false,
      notes: null,
      modelConfigurationComments: null,
      equipmentAttachmentCount: 0,
      equipmentAttachmentModels: [
        {
          equipmentAttachmentId: 0,
          equipmentId: 0,
          name: "",
          equipmentAttachmentTypeId: 0,
          description: "",
          fileType: 0,
          filePath: "",
          created: "",
          createdBy: "",
          createdById: 0,
          updated: "",
          updatedBy: "",
          updatedById: 0,
          file: "",
          size: "",
          equipmentName: "",
          equipmentAttachmentTypeName: "",
          associatedUnitName: "",
          fileTypeName: "",
          displaySize: "",
          fileTypeObj: {
            id: 0,
            name: "",
            description: "",
            extension: "",
            maxSizeMb: 0,
            contentType: "",
          },
        },
      ],
    },
  },
  plantEquipments: {
    success: false,
    message: "",
    permissions: 0,
    data: [],
  },
  typePlantContacts: {
    success: false,
    message: "",
    permissions: 0,
    data: [],
  },
  typeMitsubiContacts: {
    success: false,
    message: "",
    permissions: 0,
    data: [],
  },
  plantEquipmentId: {
    id: 0,
  },
  plantConfig: {
    success: false,
    message: "",
    permissions: 0,
    data: {
      plantId: 0,
      baseOrgId: 0,
      baseOrgName: "",
      operators: "",
      websiteURL: "",
      owner: "",
      parentGroup: "",
      address1: "",
      address2: "",
      address3: "",
      mainPhone: "",
      frequency: "",
      mhiRegion: "",
      notesComments: "",
      file: "",
      units: [],
      displayPictureSize: '',
      lastOutage: '',
      nextPlannedOutage: '',
      plantPictureSize: 0
    },
  },
  unit: {
    success: false,
    message: "",
    permissions: 0,
    data: {
      unitId: 2,
      plantId: 1,
      name: "asdas",
      parentGroupOwnership: "string",
      operator: "string",
      directOwnership: "string",
      coDate: "string",
      fuelTypeId: 1,
      unitConfigurationId: 1,
      totalMW: 1,
      notes: "string",
      fuelTypeName: "string",
      unitConfigurationName: "string",
      equipmentCount: 1,
    },
  },
  unitGetAll: {
    success: false,
    message: "",
    permissions: 0,
    data: [],
  },

  ommanualSectionGetAll: {
    success: false,
    message: "",
    permissions: 0,
    data: [],
  },

  plantInformation: {
    nameplate: {
      id: 0,
    },
  },
  plantEquipmentsAttachments: {
    success: false,
    message: "",
    permissions: 0,
    data: [
      {
        equipmentAttachmentId: 0,
        equipmentId: 0,
        name: "",
        equipmentAttachmentTypeId: 0,
        description: "",
        fileType: 0,
        filePath: "",
        created: "",
        createdBy: "",
        createdById: 0,
        updated: "",
        updatedBy: "",
        updatedById: 0,
        file: "",
        size: "",
        equipmentName: "",
        equipmentAttachmentTypeName: "",
        associatedUnitName: "",
        fileTypeName: "",
        displaySize: "",
        fileTypeObj: {
          id: 0,
          name: "",
          description: "",
          extension: "",
          maxSizeMb: 0,
          contentType: "",
        },
      },
    ],
  },
  plantEquipmentsAttachment: {
    success: false,
    message: "",
    permissions: 0,
    data: {
      equipmentAttachmentId: 0,
      equipmentId: 0,
      name: "",
      equipmentAttachmentTypeId: 0,
      description: "",
      fileType: 0,
      filePath: "",
      created: "",
      createdBy: "",
      createdById: 0,
      updated: "",
      updatedBy: "",
      updatedById: 0,
      file: "",
      size: "",
      equipmentName: "",
      equipmentAttachmentTypeName: "",
      associatedUnitName: "",
      fileTypeName: "",
      displaySize: "",
      fileTypeObj: {
        id: 0,
        name: "",
        description: "",
        extension: "",
        maxSizeMb: 0,
        contentType: "",
      },
    },
  },
  accordian_tab: 0,
  toolTip: false,
  toolTipContent: <></>,
  sidebarToggle: false,
  mobileNavToggle: false,
  trackLocation: window.location.pathname,
  plants: [],
  globalUniqueIds: [],
  userGroups: [],
  selectedUserGroup: {
    id: 0,
    name: 'Users\' Group Home',
    description: '',
    plants: [],
    enableTechnicalBulletinList: false,
  }
};

export const Store = createContext<StateInterface>(defaultState);
